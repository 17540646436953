import { useMediaQuery } from "@react-hooks-library/core";
import { SanityReference } from "next-sanity";
import { CSSProperties } from "react";
import TrackingPixel from "src/components/ads/TrackingPixel";
import CmsImageForAssetRef from "src/components/images/CmsImageForAssetRef";
import { getOrgSize } from "src/components/images/imageFunctions";
import StreamingguideLogo from "src/components/streaming/StreamingguideLogo";
import theme from "src/components/themes/DefaultTheme";
import styled from "src/lib/styles/css";

//#region [Props]
type TopOfPageSponsorProps = {
    mobileImages?: SanityReference[] | null;
    desktopImages?: SanityReference[] | null;
    clickUrl?: string | null;
    isStreaming: boolean;
    className?: string;
};
//#endregion

//#region [Component]
export default function TopOfPageSponsor({ className, mobileImages, desktopImages, clickUrl, isStreaming }: TopOfPageSponsorProps) {
    const isDesktop = useMediaQuery(theme.mq.desktop);
    if (!mobileImages && !desktopImages) return null;

    const desktopSizes = desktopImages?.map(img => getOrgSize(img._ref));
    const mobileSizes = mobileImages?.map(img => getOrgSize(img._ref));
    const styles: any = { "--sponsor-flex": (isStreaming ? "50%" : "100%") };
    if (!!desktopSizes && desktopSizes.length > 0) {
        styles["--sponsor-height-d"] = `${desktopSizes[0].height}px`;
        styles["--sponsor-flex"] = `${desktopSizes[0].width}px`;
    }

    if (!!mobileSizes && mobileSizes.length > 0) {
        styles["--sponsor-height-m"] = `${mobileSizes[0].height}px`;
    }

    const hasDesktopImages = (desktopImages?.length ?? 0) > 0;
    const hasMobileImages = (mobileImages?.length ?? 0) > 0;



    return <STopOfPageSponsorOuterContainer>
        <STopOfPageSponsorContainer style={styles as CSSProperties} className={className}>
            {isStreaming && <SSGLogo>
                <SStreamingguideLogo />
                <SCooperation>i samarbeid med</SCooperation>
            </SSGLogo>}
            <SSponsorLink as={!!clickUrl ? "a" : "div"} href={clickUrl!} target="_blank">
                {hasMobileImages && <SMobileCmsImageForAssetRef assetRef={mobileImages![0]._ref} alt="Sponsorat" />}
                {hasDesktopImages && <SDesktopCmsImageForAssetRef assetRef={desktopImages![0]._ref} alt="Sponsorat" unoptimized />}
            </SSponsorLink>

        </STopOfPageSponsorContainer>
        {((isDesktop && hasDesktopImages) || (!isDesktop && hasMobileImages)) && <STrackingPixel targetId="SponsorTrackingPixel" />}
    </STopOfPageSponsorOuterContainer>;
}
//#endregion

//#region [Styles]
const STopOfPageSponsorOuterContainer = styled.div`
    position: relative;
`;

const STopOfPageSponsorContainer = styled.div`
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: flex-start;
    max-width: min(100vw, ${theme.maxContentWidth}px);
    margin: 0;


    @media ${theme.mq.desktop} {
        flex-direction: row;
        align-items: center;
        margin: 0 0 -35px 0;
    }
`;

const SSGLogo = styled.div`
    flex: 0 0 calc(100% - ${theme.maxContentPadding});
    margin-left: ${theme.maxContentPadding};
    width: calc(100% - ${theme.maxContentPadding});

    @media ${theme.mq.desktop} {
        white-space: nowrap;
        flex: 0 0 calc(50% - ${theme.maxContentPadding});
        width: auto;
        svg {
            max-width: 100%;
        }
    }
`;

const SStreamingguideLogo = styled(StreamingguideLogo)`
    margin: 0;
    padding: 0;
    display: inline-block;
    width: calc(50% - ${theme.maxContentPadding});
    vertical-align: middle;
    @media ${theme.mq.desktop} {
        display: block;
        width: 470px;

    }


`;

const SCooperation = styled.div`
    color: var(--textcolor);
    font-size: 1rem;
    display: inline-block;
    margin-left: 0.5em;
    vertical-align: text-bottom;
    @media ${theme.mq.desktop} {
        display: block;
        font-size: 2rem;
        margin-left: 0;

    }
`;

const SSponsorLink = styled.a`
    @media ${theme.mq.desktop} {
        flex: 0 0 50%;
        max-width: var(--sponsor-flex);
    }
`;

const SMobileCmsImageForAssetRef = styled(CmsImageForAssetRef)`
    display: block;
    @media ${theme.mq.desktop} {
        display: none;
    }
`;

const SDesktopCmsImageForAssetRef = styled(CmsImageForAssetRef)`
    display: none;

    @media ${theme.mq.desktop} {
        display: block;
    }
`;

const STrackingPixel = styled(TrackingPixel)`
    position: absolute;
    left: 50%;
    bottom: 0;
`;
//#endregion