type EnvConfig = {
	[key: string]: string
};

const serverEnvConfig: EnvConfig = {
	DISABLE_FILE_CACHE: process.env.DISABLE_FILE_CACHE as string,
	AFD_CACHE_PURGE_KEY: process.env.AFD_CACHE_PURGE_KEY as string,
	REVALIDATION_TOKEN: process.env.REVALIDATION_TOKEN as string,
	REVALIDATION_SKIP_SIGNATURE_CHECK: process.env.REVALIDATION_SKIP_SIGNATURE_CHECK as string
};
// server env variables must waith with validation until they are requested.
export function getEnv(envName: string): string {
	const val = serverEnvConfig[envName];
	if (!val) {
		throw new Error(
			`SERVER: Couldn't find environment variable: ${envName}`
		);
	}
	return val;
}