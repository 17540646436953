import CloseSvg from 'src/components/svg/CloseSvg';
import theme from 'src/components/themes/DefaultTheme';
import getURLParameter from 'src/lib/client/getURLParameter';
import { useAdContext } from 'src/lib/contexts/AdContext';
import styled, { css } from 'src/lib/styles/css';
import { useEffect, useState } from 'react';


//#region [Props]
type AdProps = {
	targetId: string;
	className?: string;
};
//#endregion

//#region [Component]
export default function TrackingPixel({ targetId, className }: AdProps) {
	return <STrackingPixel id={targetId} className={className}></STrackingPixel>;
}
//#endregion

//#region [Styles]
const STrackingPixel = styled.div`
	max-width: 1px;
	max-height: 1px;
`;

//#endregion