import { GetServerSidePropsContext, GetStaticPropsContext } from "next";
import { createClient, groq, SanityClient, SanityReference } from "next-sanity";
import { getRequestMeta } from "next/dist/server/request-meta";
import { AdUnit } from "src/lib/contexts/AdContext";
import { adUnitMakeSafeSerializable, findAdEnvironment } from "src/lib/server/ads";
import { getCachedAppData, setCommonAppData } from "src/lib/server/cache";
import { getEnv } from "src/lib/server/serverEnvConfig";
import IFilmnytt from "src/lib/types/Filmnytt";
import { ICommonAppData } from "src/lib/types/ICommonAppData";


function getSanityClient(useCdn: boolean = true): SanityClient {

    return createClient({
        projectId: process.env.SANITY_PROJECT_ID,
        perspective: "published",
        dataset: process.env.SANITY_DATASET,
        apiVersion: '2023-03-30', // use current UTC date - see "specifying API version"!
        token: process.env.SANITY_TOKEN,
        useCdn: useCdn, // `false` if you want to ensure fresh data
    });


}



export function getServerData(pageDataCallback: (context: GetServerSidePropsContext) => Promise<any>) {
    return async (context: GetServerSidePropsContext) => {
        const appData = getCommonAppData();
        const pageData = pageDataCallback(context);
        const [resolvedAppData, resolvedPageData] = await Promise.all([appData, pageData]);
        if (resolvedPageData.notFound) {
            return resolvedPageData;
        }
        const isP = isPreview(context);
        const adEnv = findAdEnvironment(resolvedAppData);
        let overriddenAdunits: AdUnit[] | undefined;
        let hasSponsor: boolean = false;
        let hasSponsorBg: boolean = false;
        resolvedPageData.props.sponsorBgDesktop = null;
        resolvedPageData.props.topImageDesktop = null;
        resolvedPageData.props.topImageMobile = null;
        if (adEnv) {
            const meta = getRequestMeta(context.req);
            const filePath = meta.invokeOutput;
            if (filePath) {
                const adTargets = adEnv.targets?.filter(target => target.url.includes(filePath));
                overriddenAdunits = adTargets?.reduce<AdUnit[]>((adUnits, adTarget) => {
                    if (adTarget.adunits) {
                        adUnits = adUnits.concat(adTarget.adunits)
                    }
                    return adUnits;
                }, []);
                if (overriddenAdunits) {
                    overriddenAdunits = adUnitMakeSafeSerializable(overriddenAdunits);
                }
                const spns = adEnv.sponsorate?.find(target => target.url.includes(filePath));

                if (spns) {
                    hasSponsor = spns.isActive ?? false;
                    hasSponsorBg = spns.isActiveBg ?? false;

                    if (spns.bgDesktop) {
                        resolvedPageData.props.sponsorBgDesktop = spns.bgDesktop.asset ?? null;
                    }
                    resolvedPageData.props.sponsorClickUrl = spns.clickUrl ?? null;
                    if (spns.topImageDesktop) {
                        resolvedPageData.props.sponsorTopImageDesktop = spns.topImageDesktop.map(img => img.asset);
                    }
                    if (spns.topImageMobile) {
                        resolvedPageData.props.sponsorTopImageMobile = spns.topImageMobile.map(img => img.asset);
                    }
                    if (spns.bgVertAttach) {
                        resolvedPageData.props.bgVertAttach = spns.bgVertAttach;
                    }
                    if (spns.bgColor) {
                        resolvedPageData.props.bgColor = spns.bgColor.hex;
                    }
                }
            }
        }

        resolvedPageData.props.adUnits = overriddenAdunits ?? null;
        resolvedPageData.props.hasSponsor = hasSponsor;
        resolvedPageData.props.hasSponsorBg = hasSponsorBg;
        //console.debug("PROPS", resolvedPageData.props);
        return {
            ...resolvedPageData,
            props: {
                appData: resolvedAppData,
                isPreview: isP,
                robots: resolvedPageData["props"]?.robots ?? process.env.ROBOTS ?? "index, follow",
                envName: process.env.AZURE_ENV_NAME ?? "",
                ...resolvedPageData["props"],
            },
        };
    };
}

export function getStaticServerData(pageDataCallback: (context: GetStaticPropsContext) => Promise<any>) {
    return async (context: GetStaticPropsContext) => {
        const appData = getCommonAppData();
        const pageData = pageDataCallback(context);

        const [resolvedAppData, resolvedPageData] = await Promise.all([appData, pageData]);


        return {
            ...resolvedPageData,
            props: {
                ...resolvedPageData["props"],
                appData: resolvedAppData
            },
        };
    };
}

//#region [Other]
const DISABLE_CACHE = false;

export async function getCommonAppData(): Promise<ICommonAppData> {
    const isCacheDisabled = DISABLE_CACHE || getEnv("DISABLE_FILE_CACHE") === "true";
    console.debug("CACHE DISABLED:", isCacheDisabled);
    let commonAppData: ICommonAppData | null | undefined = isCacheDisabled ? null : await getCachedAppData();
    if (!commonAppData) {
        console.debug("Getting CLEAN common app data", commonAppData);
        const sanityClient = getSanityClient(true);
        commonAppData = await sanityClient?.fetch(groq`{
			"settings": *[_id == "settings"][0] {
				...,
				"cookiePolicy": cookiePolicy->slug,
                "termsAndConditions": termsAndConditions->slug
			},
			"streamingToolbox": *[_id == "streamingToolbox"][0] {
				...,
				'links': links_v2[]{
					_type == 'articleWithOverriddenTitle' => @ {
						_type,
						_key,
                        "title": article->title,
                        titleOverride,
						"slug": article->slug,
						toolboxImage,
						"image": article->image[0]-> {
                            _type,
							_id,
							image,
							altText
                        }
					},
                    _type == "link" => @ {
                        ...,
    					image-> {
							_type,
							_id,
							image,
							altText
						}
                    }
				}
			},
            "adEnvironment": *[_id == $adEnv][0]
		}`, { adEnv: "AD_" + (process.env.AD_ENV_NAME ?? "PROD") });
        /*
        "menu": *[_id == "menus"][0] {
                "dropDownMenu": @.dropDownMenu[] {
                    ...,
                    customIcon,
                    _type == 'newsRef' => @.newsRefArticle->{title, slug},
                    _type == 'specialShowRef' => @->{title, slug, icon, customIcon},
                },
                "buttonrow": @.buttonrow[] {
                    ...,
                    _type == 'newsRef' => @->{title, slug},
                },
                messageBanner {
                    ...,
                    article->
                }
            },
           "footer": *[_id == 'footer'][0] {
                ${createRichTextQueryFragment("col1")},
                ${createRichTextQueryFragment("col2")},
                ${createRichTextQueryFragment("col3")}
           }
        */
        if (!isCacheDisabled && commonAppData) {
            await setCommonAppData(commonAppData);
        }

    } else {
        console.debug("Getting CACHED common app data", (commonAppData as ICommonAppData).settings._rev);
    }

    return commonAppData as ICommonAppData;
}



const slugByEceIdQuery: string = groq`*[eceId == $eceId][0].slug.current`;

// helper function to find a slug when we have ece id
export async function getSlugByEceId(eceId: string): Promise<string | null> {
    return await getSanityClient().fetch(slugByEceIdQuery, { eceId: Number(eceId) });
}

//#region [Other] sanityQuery
type QueryParams = { [key: string]: any };
export interface IQueryResult<T> {
    published: T;
}


export interface IQueryResultWithNews<T> extends IQueryResult<T> {
    news?: IFilmnytt[];
}

type SanityQueryOpts = {
    useCdn?: boolean;
    isPreview?: boolean;
    timerLabel?: string;
}

export async function sanityQuery<T>(query: string, params?: QueryParams, options: SanityQueryOpts | null = null): Promise<T | undefined> {

    if (options?.timerLabel) {
        console.time(options!.timerLabel)
    }
    //console.debug("fwpakkeQuery", "use CDN: " + useCdn, "query", query.substring(0, 50));
    let sanityClient = getSanityClient(options?.useCdn ?? false);
    if (options?.isPreview) {
        sanityClient = sanityClient.withConfig({
            perspective: "previewDrafts",
            useCdn: false,
            ignoreBrowserTokenWarning: true
        })
    }
    try {
        return await sanityClient?.fetch<T | undefined>(query, params ?? {});
    } catch (e) {
        console.error(e);
        throw e;
    } finally {
        if (options?.timerLabel) {
            console.timeEnd(options!.timerLabel);
        }
    }
}
//#endregion

export function isPreview(context: GetServerSidePropsContext, skipCookie: boolean = true): boolean {
    const isPreview = context.req.cookies["__preview"] === process.env.CUSTOM_PREVIEW_TOKEN as string;
    const isP = context.query?.p === "1" && (skipCookie || context.draftMode || isPreview);

    return isP;
}


//#region [Other] ICommonProps
export interface ICommonProps {
    appData: ICommonAppData;
    dehydratedState?: unknown;
    isPreview?: boolean;
    adUnits?: AdUnit[];
    hasSponsor: boolean; // from settings
    hasSponsorBg: boolean; // from settings
    sponsorBgDesktop?: SanityReference | null;
    sponsorClickUrl?: string | null;
    sponsorTopImageDesktop?: SanityReference[] | null;
    sponsorTopImageMobile?: SanityReference[] | null;
}
//#endregion

export async function kinoklubbSanityQuery<T>(query: string, params?: QueryParams): Promise<T | undefined> {
    const sanityClient = createClient({
        projectId: process.env.NEXT_PUBLIC_KINOKLUBB_SANITY_PROJECTID,
        perspective: "published",
        dataset: process.env.NEXT_PUBLIC_KINOKLUBB_SANITY_DATASET,
        apiVersion: '2023-03-30', // use current UTC date - see "specifying API version"!
        token: process.env.SANITY_KINOKLUBB_TOKEN,
        useCdn: true, // `false` if you want to ensure fresh data
    });

    return sanityClient.fetch<T | undefined>(query, params ?? {});
}